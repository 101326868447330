import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageService {
  private messageDetails = signal<{
    type: 'success' | 'error' | 'warn';
    value: string;
  }>({
    type: 'success',
    value: '',
  });
  private showMessage = signal(false);
  private customClass = signal('');

  success(message: string, customClass = '') {
    this.messageDetails.set({
      type: 'success',
      value: message,
    });
    this.customClass.set(customClass);
    this.showMessage.set(true);
  }

  error(message: string, customClass = '') {
    this.messageDetails.set({
      type: 'error',
      value: message,
    });
    this.customClass.set(customClass);
    this.showMessage.set(true);
  }

  getShowAlert() {
    return this.showMessage;
  }

  getMessageDetails() {
    return this.messageDetails;
  }

  getCustomClass() {
    return this.customClass;
  }

  closeAlert() {
    this.messageDetails.set({
      type: 'success',
      value: '',
    });
    this.customClass.set('');
    this.showMessage.set(false);
  }
}
